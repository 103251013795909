import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoText from '../../../images/logo-text.png';
import logo from "../../../images/logo-square.png";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector('#main-wrapper');
    if (mainwrapper.classList.contains('menu-toggle')) {
      mainwrapper.classList.remove('menu-toggle');
    } else {
      mainwrapper.classList.add('menu-toggle');
    }
  }, 200);
}

const NavHeader = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className='nav-header'>
      <Link to='/' className='brand-logo'>
        <img className='logo-abbr' src={logo} alt='' />
        <img className='brand-title' src={logoText} alt='logo' />
      </Link>

      <div
        className='nav-control'
        onClick={() => {
          setToggle(!toggle);
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? 'is-active' : ''}`}>
          <span className='line'></span>
          <span className='line'></span>
          <span className='line'></span>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
