import React from 'react';
import LogoutPage from './Logout';
import profile from '../../../images/avatar/25.png';
import { Dropdown } from 'react-bootstrap';

const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {
  return (
    <div className='header'>
      <div className='header-content'>
        <nav className='navbar navbar-expand'>
          <div className='collapse navbar-collapse justify-content-between'>
            <div className='header-left'></div>
            <ul className='navbar-nav header-right'>
              <Dropdown as='li' className='nav-item header-profile '>
                <Dropdown.Toggle
                  as='a'
                  to='#'
                  variant=''
                  className='nav-link i-false c-pointer'
                >
                  <div className='header-info'>
                    <span className='text-black'>
                      Hello, <strong>Admin</strong>
                    </span>
                    <p className='fs-12 mb-0'>Administrator</p>
                  </div>
                  <img src={profile} width={20} alt='profile' />
                </Dropdown.Toggle>
                <Dropdown.Menu align='end' className='mt-2'>
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
