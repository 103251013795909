export const MenuList = [
  {
    title: 'Dashboard',
    classsChange: 'mm-collapse',
    iconStyle: <i className='flaticon-381-networking'></i>,
    content: [
      {
        title: 'Analytics',
        to: '/dashboard',
      },
      {
        title: 'My Wallet',
        to: '/my-wallet',
      },
      {
        title: 'Projects',
        to: '/projects',
      },
      {
        title: 'Invoices',
        to: '/invoices',
      },
    //   {
    //     title: 'Cards Center',
    //     to: '/cards-center',
    //   },
    //   {
    //     title: 'Transactions',
    //     to: '/transactions',
    //   },
    //   {
    //     title: 'Transactions Details',
    //     to: '/transactions-details',
    //   },
    //   {
    //     title: 'Task',
    //     to: '/task',
    //   },
    ],
  },
];
