import React, { useRef } from 'react';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import data from './tableData.js';
const FeesCollection = () => {
  const sort = 3;
  let jobPagination = Array(Math.ceil(data.feeTable.data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePage = useRef(0);
  const jobData = useRef(
    data.feeTable.data.slice(
      activePage.current * sort,
      (activePage.current + 1) * sort
    )
  );
  const onClick = (i) => {
    activePage.current = i;

    jobData.current = data.feeTable.data.slice(
      activePage.current * sort,
      (activePage.current + 1) * sort
    );
  };
  return (
    <div className='col-12'>
      <div className='card'>
        <div className='card-header'>
          <h4 className='card-title'>Fees Collection</h4>
        </div>
        <div className='card-body'>
          <div className='w-100 table-responsive'>
            <div id='example_wrapper' className='dataTables_wrapper'>
              <table id='example' className='display w-100 dataTable'>
                <thead>
                  <tr role='row'>
                    {data.feeTable.columns.map((d, i) => (
                      <th key={i}>{d}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {jobData.current.map((d, i) => (
                    <tr key={i}>
                      {d.map((da, ii) => (
                        <td key={ii}>
                          {da === 'Paid' ? (
                            <Badge variant='success light'>Paid</Badge>
                          ) : da === 'Unpaid' ? (
                            <Badge variant='danger light'>Unpaid</Badge>
                          ) : da === 'Panding' ? (
                            <Badge variant='warning light'>Panding</Badge>
                          ) : (
                            da
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr role='row'>
                    {data.feeTable.columns.map((d, i) => (
                      <th key={i}>{d}</th>
                    ))}
                  </tr>
                </tfoot>
              </table>
              <div className='d-sm-flex text-center justify-content-between align-items-center mt-3'>
                <div className='dataTables_info'>
                  Showing {activePage.current * sort + 1} to{' '}
                  {data.length > (activePage.current + 1) * sort
                    ? (activePage.current + 1) * sort
                    : data.length}{' '}
                  of {data.length} entries
                </div>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='example5_paginate'
                >
                  <Link
                    className='paginate_button previous disabled'
                    to='/table-datatable-basic'
                    onClick={() =>
                      activePage.current > 0 && onClick(activePage.current - 1)
                    }
                  >
                    Previous
                  </Link>
                  <span>
                    {jobPagination.map((number, i) => (
                      <Link
                        key={i}
                        to='/table-datatable-basic'
                        className={`paginate_button  ${
                          activePage.current === i ? 'current' : ''
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className='paginate_button next'
                    to='/table-datatable-basic'
                    onClick={() =>
                      activePage.current + 1 < jobPagination.length &&
                      onClick(activePage.current + 1)
                    }
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeesCollection;
