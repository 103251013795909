import swal from 'sweetalert';
import { AxiosError } from 'axios';
import { Loader } from '../Loader';
import axios from '../../../lib/axios';
import React, { Fragment } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { formatCurrencyValue } from './Invoices';
import { QueryClient, useQuery } from '@tanstack/react-query';

const Projects = () => {
  const queryClient = new QueryClient();

  const [createModal, setCreateModal] = React.useState(false);
  const [addFormData, setAddFormData] = React.useState({
    name: '',
  });

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleAddFormSubmit = async (event) => {
    try {
      event.preventDefault();
      let error = false;
      let errorMessage = '';

      if (!addFormData.name) {
        error = true;
        errorMessage = 'Please enter project name';
      }

      if (error) {
        swal('Oops', errorMessage, 'error');
        return;
      }

      await axios.post('/projects', addFormData);

      queryClient.invalidateQueries('projects');

      setCreateModal(false);

      setTimeout(() => {
        window.location.reload();
      }, 2500);

      swal('Success', 'Project added successfully', 'success');
    } catch (error) {
      if (error instanceof AxiosError) {
        swal('Oops', error.response?.data.message, 'error');
      } else {
        swal('Oops', error.message, 'error');
      }
    }
  };

  const [editModal, setEditModal] = React.useState(false);
  const [editFormData, setEditFormData] = React.useState({
    id: '',
    name: '',
  });

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = async (event) => {
    try {
      event.preventDefault();
      let error = false;
      let errorMessage = '';

      if (!editFormData.name) {
        error = true;
        errorMessage = 'Please enter project name';
      }

      if (error) {
        swal('Oops', errorMessage, 'error');
        return;
      }

      await axios.patch(`/projects/${editFormData.id}`, editFormData);

      queryClient.invalidateQueries('projects');

      setEditModal(false);

      setTimeout(() => {
        window.location.reload();
      }, 2500);

      swal('Success', 'Project updated successfully', 'success');
    } catch (error) {
      if (error instanceof AxiosError) {
        swal('Oops', error.response?.data.message, 'error');
      } else {
        swal('Oops', error.message, 'error');
      }
    }
  };

  const handleDeleteClick = async (projectId) => {
    try {
      await axios.delete(`/projects/${projectId}`);

      queryClient.invalidateQueries('projects');

      swal('Success', 'Project deleted successfully', 'success');

      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } catch (error) {
      if (error instanceof AxiosError) {
        swal('Oops', error.response?.data.message, 'error');
      } else {
        swal('Oops', error.message, 'error');
      }
    }
  };

  async function getProjects() {
    const { data } = await axios.get('/projects');

    return data;
  }

  const { data: projects, isLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: getProjects,
  });

  return (
    <Fragment>
      <div className='form-head mb-4'>
        <h2 className='text-black font-w600 mb-0'>Projects</h2>
      </div>
      <div className='row'>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className='col-xl-12'>
              <div className='d-sm-flex d-block align-items-center mb-4'>
                <div className='me-auto'>
                  <h4 className='fs-20 text-black'>List of Projects</h4>
                  <span className='fs-12'>
                    Below table shows the list of all projects
                  </span>
                </div>

                <Button
                  className='me-2'
                  variant='primary btn-rounded'
                  onClick={() => setCreateModal(true)}
                >
                  <span className='btn-icon-left text-primary'>
                    <i className='fa fa-plus color-primary' />
                  </span>
                  Add new
                </Button>

                {/* Create Modal */}
                <Modal
                  className='modal fade'
                  show={createModal}
                  onHide={setCreateModal}
                >
                  <div className=''>
                    <div className=''>
                      <form>
                        <div className='modal-header'>
                          <h4 className='modal-title fs-20'>Add Project</h4>
                          <button
                            type='button'
                            className='btn close'
                            onClick={() => setCreateModal(false)}
                          >
                            <span>×</span>
                          </button>
                        </div>
                        <div className='modal-body'>
                          <i className='flaticon-cancel-12 close'></i>
                          <div className='add-contact-box'>
                            <div className='add-contact-content'>
                              <div className='form-group mb-3'>
                                <label className='text-black font-w500'>
                                  Project Name
                                </label>
                                <div className='contact-name'>
                                  <input
                                    type='text'
                                    className='form-control'
                                    autocomplete='off'
                                    name='name'
                                    required='required'
                                    onChange={handleAddFormChange}
                                    placeholder='Jabar Bersih'
                                  />
                                  <span className='validation-text'></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer'>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={handleAddFormSubmit}
                          >
                            Add
                          </button>
                          <button
                            type='button'
                            onClick={() => setCreateModal(false)}
                            className='btn btn-danger'
                          >
                            {' '}
                            <i className='flaticon-delete-1'></i>Discard
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Modal>

                {/* Edit Modal */}
                <Modal
                  className='modal fade'
                  show={editModal}
                  onHide={setEditModal}
                >
                  <div className=''>
                    <div className=''>
                      <form>
                        <div className='modal-header'>
                          <h4 className='modal-title fs-20'>Edit Project</h4>
                          <button
                            type='button'
                            className='btn close'
                            onClick={() => setEditModal(false)}
                          >
                            <span>×</span>
                          </button>
                        </div>
                        <div className='modal-body'>
                          <i className='flaticon-cancel-12 close'></i>
                          <div className='add-contact-box'>
                            <div className='add-contact-content'>
                              <div className='form-group mb-3'>
                                <label className='text-black font-w500'>
                                  Project Name
                                </label>
                                <div className='contact-name'>
                                  <input
                                    type='text'
                                    className='form-control'
                                    autocomplete='off'
                                    name='name'
                                    required='required'
                                    onChange={handleEditFormChange}
                                    placeholder={editFormData.name}
                                    initialValue={editFormData.name}
                                  />
                                  <span className='validation-text'></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer'>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={handleEditFormSubmit}
                          >
                            Update
                          </button>
                          <button
                            type='button'
                            onClick={() => setEditModal(false)}
                            className='btn btn-danger'
                          >
                            {' '}
                            <i className='flaticon-delete-1'></i>Discard
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>

            <div className='col-lg-12'>
              <div
                className='table-responsive table-hover fs-14 dataTables_wrapper'
                id='projects-data'
              >
                <table className='table display mb-4 dataTablesCard dataTable no-footer'>
                  <thead>
                    <tr role='row'>
                      <th>ID</th>
                      <th>Project Name</th>
                      <th>Total Amount (USD)</th>
                      {/* <th>Created At</th>
                      <th>Updated at</th> */}
                      <th>Actions</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects?.map((project) => (
                      <tr role='row' key={project.id}>
                        <td>{project.id}</td>
                        <td>{project.name}</td>
                        <td>{formatCurrencyValue(project.total_amount)}</td>
                        {/* <td>{dayjs(project.createdAt).format('D/MM/YYYY')}</td>
                        <td>{dayjs(project.updatedAt).format('D/MM/YYYY')}</td> */}
                        <td>
                          <ul class='list-inline m-0'>
                            <li class='list-inline-item'>
                              <button
                                class='btn btn-facebook btn-sm rounded'
                                type='button'
                                data-toggle='tooltip'
                                data-placement='top'
                                title='Edit'
                                onClick={() => {
                                  setEditModal(true);
                                  setEditFormData({
                                    id: project.id,
                                    name: project.name,
                                  });
                                }}
                              >
                                <i class='fa fa-edit'></i>
                              </button>
                            </li>
                            {project.total_amount === 0 && (
                              <li class='list-inline-item'>
                                <div className='sweetalert'>
                                  <button
                                    onClick={() =>
                                      swal({
                                        title: 'Are you sure?',
                                        icon: 'warning',
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          handleDeleteClick(project.id);
                                        }
                                      })
                                    }
                                    className='btn btn-warning btn sweet-success-cancel'
                                  >
                                    <i class='fa fa-trash'></i>
                                  </button>
                                </div>
                              </li>
                            )}
                          </ul>
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default Projects;
