import React, { Fragment } from 'react';
import axios from '../../../lib/axios';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '../Loader';
import { formatCurrencyValue } from '../Dashboard/Invoices';
import swal from 'sweetalert';
import { AxiosError } from 'axios';
import Error404 from '../../pages/Error404';

const Payment = () => {
  const invoice_id = new URLSearchParams(document.location.search).get(
    'invoice'
  );
  const [paymentData, setPaymentData] = React.useState({
    invoice_id,
    method: '',
    name: '',
    card_number: '',
    expiration: '',
    cvv: '',
  });

  const handleFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const formData = { ...paymentData };
    formData[fieldName] = fieldValue;

    setPaymentData(formData);
  };

  const handleFormSubmit = async (event) => {
    try {
      event.preventDefault();
      let error = false;
      let errorMessage = '';

      if (!paymentData.method) {
        error = true;
        errorMessage = 'Please select payment method';
      }

      if (!paymentData.name) {
        error = true;
        errorMessage = 'Please enter name';
      }

      // Card number regex pattern (16 digits)
      const cardNumberPattern = /^\d{16}$/;
      if (
        !paymentData.card_number ||
        !cardNumberPattern.test(paymentData.card_number)
      ) {
        error = true;
        errorMessage = 'Please enter a valid 16-digit card number';
      }

      // Expiration date regex pattern (MM/YY format)
      const expirationPattern = /^(0[1-9]|1[0-2])\/\d{2}$/;
      if (
        !paymentData.expiration ||
        !expirationPattern.test(paymentData.expiration)
      ) {
        error = true;
        errorMessage = 'Please enter a valid expiration date in MM/YY format';
      }

      // CVV regex pattern (3 digits)
      const cvvPattern = /^\d{3}$/;
      if (!paymentData.cvv || !cvvPattern.test(paymentData.cvv)) {
        error = true;
        errorMessage = 'Please enter a valid 3-digit CVV';
      }

      if (error) {
        swal('Oops', errorMessage, 'error');
        return;
      }

      swal({
        title: 'Processing payment',
        text: 'Please wait...',
        icon: 'info',
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

      // TODO: 1. Hit the payment api gateway

      // TODO: 2. Update invoice status based on returned value from payment gateway

      const { data } = await axios.get('/invoices');

      swal.close();

      swal({
        title: 'Payment success',
        text: 'Please check email for payment receipt. You can now close this window.',
        // text: JSON.stringify(paymentData),
        icon: 'success',
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        swal('Oops', error.response?.invoice.message, 'error');
      } else {
        swal('Oops', error.message, 'error');
      }
    }
  };

  async function getInvoiceData() {
    const { data } = await axios.get(`/invoices/${invoice_id}`);
    return data;
  }

  const { data: invoice, isLoading } = useQuery({
    queryKey: ['invoice', invoice_id],
    queryFn: getInvoiceData,
  });

  return isLoading ? (
    <Loader />
  ) : invoice.status === 'SUCCESS' ? (
    <Error404 />
  ) : (
    <Fragment>
      <div className='row'>
        <div className='col-xl-12'>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-4 order-md-2 mb-4'>
                  <h4 className='d-flex justify-content-between align-items-center mb-3'>
                    <span className='text-muted'>Your cart</span>
                  </h4>
                  <div
                    style={{
                      maxHeight: '300px',
                      overflowY: 'auto',
                    }}
                  >
                    <ul className='list-group mb-3'>
                      {invoice.items.map((item) => (
                        <li className='list-group-item d-flex justify-content-between lh-condensed'>
                          <div>
                            <h6 className='my-0'>{item.name}</h6>
                            <small className='text-muted'>
                              Quantity: {item.quantity}
                            </small>
                          </div>
                          <span className='text-muted'>{`$ ${formatCurrencyValue(
                            item.price
                          )}`}</span>
                        </li>
                      ))}
                      <li className='list-group-item d-flex justify-content-between'>
                        <span>Total</span>
                        <strong>{`$ ${formatCurrencyValue(
                          invoice.total_price
                        )}`}</strong>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='col-md-8 order-md-1'>
                  <h4 className='mb-3'>Please fill your payment details</h4>
                  <form
                    className='needs-validation'
                    noValidate
                    style={{
                      maxHeight: '450px',
                      overflowX: 'hidden',
                      overflowY: 'auto'
                    }}
                  >
                    <div className='d-block my-3'>
                      <div className='form-check custom-radio mb-2'>
                        <input
                          id='credit'
                          name='method'
                          type='radio'
                          class='form-check-input'
                          value='credit'
                          onChange={handleFormChange}
                          required
                        />
                        <label className='form-check-label' htmlFor='credit'>
                          Credit card
                        </label>
                      </div>
                      <div className='form-check custom-radio mb-2'>
                        <input
                          id='debit'
                          name='method'
                          type='radio'
                          class='form-check-input'
                          value='debit'
                          onChange={handleFormChange}
                          required
                        />
                        <label className='form-check-label' htmlFor='debit'>
                          Debit card
                        </label>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='cc-name'>Name on card</label>
                        <input
                          type='text'
                          class='form-control'
                          id='cc-name'
                          name='name'
                          placeholder='John Doe'
                          onChange={handleFormChange}
                          required
                        />
                        <small className='text-muted'>
                          Full name as displayed on card
                        </small>
                        <div className='invalid-feedback'>
                          Name on card is required
                        </div>
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='cc-number'>Card number</label>
                        <input
                          type='text'
                          class='form-control'
                          id='cc-number'
                          name='card_number'
                          placeholder='0000 0000 0000 0000'
                          required
                          pattern='[0-9]{16}'
                          onChange={handleFormChange}
                        />
                        <div className='invalid-feedback'>
                          Credit card number is required and must be 16 digits.
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-3 mb-3'>
                        <label htmlFor='cc-expiration'>Expiration</label>
                        <input
                          type='text'
                          class='form-control'
                          id='cc-expiration'
                          name='expiration'
                          placeholder='07/25'
                          required
                          pattern='^(0[1-9]|1[0-2])\/\d{2}$'
                          onChange={handleFormChange}
                        />
                        <div className='invalid-feedback'>
                          Expiration date required (MM/YY format).
                        </div>
                      </div>
                      <div className='col-md-3 mb-3'>
                        <label htmlFor='cc-cvv'>CVV</label>
                        <input
                          type='text'
                          class='form-control'
                          id='cc-cvv'
                          name='cvv'
                          placeholder='563'
                          required
                          pattern='^\d{3}$'
                          onChange={handleFormChange}
                        />
                        <div className='invalid-feedback'>
                          Security code required (3 digits).
                        </div>
                      </div>
                    </div>
                    <hr className='mb-4' />
                    <button
                      className='btn btn-primary btn-lg btn-block'
                      type='submit'
                      onClick={handleFormSubmit}
                    >
                      Pay
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Payment;
